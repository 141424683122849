<template>
    <div class="companies-invited">
        <CCard>
            <CCardBody>
                <CRow class="gx-0 gx-lg-3 justify-content-between w-100">
                    <CCol class="GCFilterMaxWidth col-12">
                        <GCFilter component="InvitedCompany" @search="val => onTimeoutSearch(() => fetchData(val))"/>
                    </CCol>
                </CRow>

                <SmartTable :items="companyInvitations" :items-per-page="parseInt(perPage)" :columns="tableColumns"
                            :sorter-value="sort" :loading="loadingTable" :active-page="activePage" :per-page="perPage"
                            :pages="pages" :clear-text="search.length ? 'No search results found' : 'No Data yet'"
                            @sorter-change="onSort" @update-active-page="(value) => (activePage = value)"
                            @update-per-page="(value) => (perPage = value)" style="margin-top: 14px">
                    <template #invited_company.name="{ item, column }">
                        <span v-if="column.key === 'invited_company.name'">
                          {{ item.invited_company.name }}
                        </span>
                    </template>
                    <template #status="{ item, column }">
                        <span v-if="column.key === 'status'" class="text-capitalize"
                              :class="getTableItemStatusClass(item.status)">
                          {{ item.status }}
                        </span>
                    </template>

                    <template #button="{ item }">
                        <td>
                            <div class="companies-invited__table-buttons">
                                <button type="button"
                                        class="companies-invited__table-button companies-invited__table-button--dark"
                                        @click="openInvitationDetailsModal(item)">
                                    <CIcon class="companies-invited__table-button-icon" name="cil-info"/>
                                    <span class="companies-invited__table-button-text">View details</span>
                                </button>

                                <button v-if="item.status !== 'accepted'" type="button"
                                        class="companies-invited__table-button companies-invited__table-button--blue"
                                        @click="resendInvite(item)">
                                    <CIcon class="companies-invited__table-button-icon" name="cil-reload"/>
                                    <span class="companies-invited__table-button-text">Resend invite</span>
                                </button>

                                <button v-if="item.status === 'pending'" type="button"
                                        class="companies-invited__table-button companies-invited__table-button--red"
                                        @click="openCancelInvitationModal(item)">
                                    <CIcon class="companies-invited__table-button-icon" name="cil-x"/>
                                    <span class="companies-invited__table-button-text">Cancel invite</span>
                                </button>
                            </div>
                        </td>
                    </template>
                </SmartTable>
            </CCardBody>
        </CCard>

        <CModal :visible="showCancelInvitationModal && selectedInvitation" size="lg"
                content-class-name="companies-invited__modal-cancel modal-cancel" @close="closeModals">
            <CModalBody>
                <h2 class="modal-cancel__title">
                    Are you sure you want to cancel the invite to <br/>
                    {{ selectedInvitation.invited_company.name }}?
                </h2>
            </CModalBody>

            <CModalFooter class="modal-cancel__buttons">
                <CButton class="modal-cancel__button" color="dark" variant="outline" @click="closeModals">No</CButton>
                <CButton color="primary" class="modal-cancel__button" @click="cancelInvite(selectedInvitation)">
                    Yes
                </CButton>
            </CModalFooter>
        </CModal>

        <CModal :visible="showInvitationDetailsModal && selectedInvitation" size="lg"
                content-class-name="companies-invited__modal-invite-details modal-invite-details" @close="closeModals">
            <CModalBody>
                <h2 class="modal-invite-details__title">Invite details</h2>

                <CForm>
                    <div class="modal-invite-details__input-group">
                        <div class="modal-invite-details__input">
                            <CFormLabel class="modal-invite-details__label">Company Name</CFormLabel>
                            <CFormInput class="modal-invite-details__field" placeholder="name@example.com"
                                        :value="selectedInvitation.invited_company.name || 'N/A'" readonly/>
                        </div>

                        <div class="modal-invite-details__input">
                            <CFormLabel class="modal-invite-details__label">DBA</CFormLabel>

                            <CFormInput class="modal-invite-details__field" placeholder="name@example.com"
                                        :value="selectedInvitation.invited_company.dba || 'N/A'" readonly/>
                        </div>
                    </div>

                    <div class="modal-invite-details__input-group">
                        <div class="modal-invite-details__input">
                            <CFormLabel class="modal-invite-details__label">Website</CFormLabel>
                            <CFormInput class="modal-invite-details__field" placeholder="www.website.com"
                                        :value="selectedInvitation.invited_company.website || 'N/A'" readonly/>
                        </div>

                        <div class="modal-invite-details__input">
                            <CFormLabel class="modal-invite-details__label">Tax ID / EIN</CFormLabel>
                            <CFormInput class="modal-invite-details__field" placeholder="name@example.com"
                                        :value="selectedInvitation.invited_company.tid || 'N/A'" readonly/>
                        </div>
                    </div>
                </CForm>
            </CModalBody>

            <CModalFooter class="modal-invite-details__buttons">
                <CButton class="modal-invite-details__button" color="dark" variant="outline" @click="closeModals">
                    Close
                </CButton>

                <CButton v-if="selectedInvitation.status !== 'accepted'" color="primary"
                         class="modal-invite-details__button" @click="resendInvite(selectedInvitation)">
                    Resend invite
                </CButton>
            </CModalFooter>
        </CModal>
    </div>
</template>

<script>
import GCFilter from '@/components/GCFilter/index'
import table from '@/mixin/table'
import changeSelectPage from '@/mixin/changeSelectPage'
import CompanyInvitations from "@/api/v2/endpoints/CompanyInvitations";
import apiErrorHandler from "@/mixin/apiErrorHandler";

export default {
    inject: ['toast'],
    mixins: [table, changeSelectPage, apiErrorHandler],
    components: {GCFilter},
    data() {
        return {
            companyInvitations: [],
            selectedInvitation: null,
            showInvitationDetailsModal: false,
            showCancelInvitationModal: false,
            tableColumns: [
                {
                    key: 'invited_company.name',
                    label: 'Company Name',
                    _style: '',
                },
                {
                    key: 'status',
                    label: 'Status',
                },
                {
                    key: 'created_at',
                    label: 'Invite date',
                    format: (v) => (v ? this.$BtoF({value: v}) : null),
                },
                {
                    key: 'expires_at',
                    label: 'Link expires in',
                    format: (v) => (v && v !== '-' ? this.$BtoF({value: v}) : '-'),
                },
                {
                    key: 'email',
                    label: 'Contact Email',
                    email: true,
                },
            ]
        }
    },
    methods: {
        fetchData(f = {}) {
            this.search = f.search || this.$route.query.search || this.search
            this.toggleLoadingTable();

            CompanyInvitations
                .index(this.defineFetchDataParams())
                .then(response => {
                    this.companyInvitations = response.data;
                    this.pages = response.meta.last_page;
                })
                .finally(() => this.toggleLoadingTable())
        },
        defineFetchDataParams(f = {}) {
            return {
                ...f,
                per_page: this.perPage,
                page: this.activePage,
                order_by: this.sort && this.sort.column,
                order_dir: this.sort && this.sort.state,
                with_invited_company: 'name,dba,website,tid'
            };
        },
        resendInvite(companyInvitation) {
            CompanyInvitations
                .resend(companyInvitation.id)
                .then(() => {
                    this.toast('info', 'The invitation has been resent successfully!');
                    this.closeModals();
                    this.fetchData();
                })
                .catch(response => this.handleApiError(response));
        },
        cancelInvite(companyInvitation) {
            CompanyInvitations
                .cancel(companyInvitation.id)
                .then(() => {
                    this.toast('info', 'The invitation has been cancelled successfully!')
                    this.closeModals()
                    this.fetchData()
                })
                .catch(response => this.handleApiError(response));
        },
        openCancelInvitationModal(companyInvitation) {
            this.selectedInvitation = companyInvitation;
            this.showCancelInvitationModal = true;
        },
        openInvitationDetailsModal(companyInvitation) {
            this.selectedInvitation = companyInvitation;
            this.showInvitationDetailsModal = true;
        },
        closeModals() {
            this.showCancelInvitationModal = false;
            this.showInvitationDetailsModal = false;
            this.selectedInvitation = null;
        },
        getTableItemStatusClass(status) {
            if (status === 'pending') {
                return 'companies-invited__status--blue';
            }

            if (status === 'accepted') {
                return 'companies-invited__status--green';
            }

            return 'companies-invited__status--red';
        },
        toggleLoadingTable() {
            this.loadingTable = !this.loadingTable;
        }
    },
}
</script>

<style lang="scss">
@import 'style';
</style>
